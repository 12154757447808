import * as React from "react";
import { Link } from "gatsby";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import { doNothingAnchor } from "../../components/Old2005BlogPage";
import NewsHeader from "../../components/NewsHeader";

const NewsPage = () => (
  <Layout>
    <Seo title="News—January 2010" />
	<NewsHeader />
    <h2>January 2010</h2>



	<h3 className="blogdate">
	Thursday, January 14, 2010
	</h3>

	<h4 className="blogitemtitle">
	NZ South Island - I'm coming soon!
	</h4>
	   <div className="blogitembody">
	   <p>Hi everyone,</p>

	<p>
	I'm excited to announce that I'll be playing some shows in the sunshine
	and glow of the upper South Island of New Zealand in February - I'm
	really looking forward to it!
	</p>
	<p>
	I hope you or someone you know will come along to one of the shows;
	better come up and say hi, too - it's cool to meet new people. Plus the
	shows are small, right, so let's meet.
	</p>
	<p>
	The very best way to stay in the loop for this tour is:
	</p>
	<ul>
	<li>
	Keep an eye on <a href="/" onClick={doNothingAnchor}>these
	feed items (long since removed)</a>, I'll update them as needed
	</li>
	<li>
	Follow me on <a href="http://www.twitter.com/jesserivest">Twitter</a>
	(free to NZ phones)
	</li>
	<li>
	Join the community at <a href="http://www.facebook.com/jesserivest">my
	Facebook page</a> or<a href="http://www.facebook.com/event.php?eid=282180815790">
	tour event page</a>
	</li>
	</ul>
	<p>I'll be playing:</p>

	<ul>
	<li>
	<b><span>Waihi Bush Music Festival</span></b> (Geraldine area) on Feb
	4 through Feb 6/7
	</li>
	<li>
	Wunderbar in <span><b>Lyttelton</b></span> (Christchurch-ish) on Feb 7
	</li>
	<li>
	The Mussel Inn in <span><b>Golden Bay</b></span> on Feb 19
	</li>
	<li>
	Hot Mama's in <span><b>Motueka</b></span> on Feb 20
	</li>
	<li>
	Le Cafe in <span><b>Picton</b></span> on Feb 21
	</li>
	</ul>
	<p>Looking forward to seeing you there!</p>

	<p>
	Jesse
	</p>
	   </div>
	   	   <div className="blogitemfooter">
	   <p>posted by Jesse @ 19:09 +1300</p>
	   </div>




	<p>
		<Link to="/news/">Go back to the news index page</Link>
	</p>
  </Layout>
);

export default NewsPage;
